@use "@angular/material" as mat;
@use "app/core/theming" as wzg;
@use "sass:map";

$wzg-typography: mat.define-typography-config(
  $headline: mat.define-typography-level(20px, 28px, 500, "Poppins"),
  $title: mat.define-typography-level(19px, 32px, 500, "Poppins"),
  $subheading-2: mat.define-typography-level(16px, 28px, 400, "Poppins"),
  $subheading-1: mat.define-typography-level(15px, 24px, 400, "Poppins"),
);

@include mat.core($wzg-typography);

$wzg-primary: mat.define-palette(wzg.$green-palette, 200, 100, 400, 500);
$wzg-accent: mat.define-palette(mat.$orange-palette, 200, 100, 500);
$wzg-warn: mat.define-palette(mat.$red-palette);

$wzg-theme: wzg.define-light-theme(
  (
    color: (
      primary: $wzg-primary,
      accent: $wzg-accent,
      warn: $wzg-warn,
    ),
    typography: $wzg-typography,
  )
);

$wzg-dark-primary: mat.define-palette(wzg.$green-palette, 200, 100, 400);
$wzg-dark-accent: mat.define-palette(mat.$orange-palette, 200, 100, 500);
$wzg-dark-warn: mat.define-palette(mat.$deep-orange-palette);

$wzg-dark-theme: wzg.define-dark-theme(
  (
    color: (
      primary: $wzg-dark-primary,
      accent: $wzg-dark-accent,
      warn: $wzg-dark-warn,
    ),
    typography: $wzg-typography,
  )
);

@include mat.core-theme($wzg-theme);
@include mat.button-theme($wzg-theme);
@include mat.icon-theme($wzg-theme);
@include mat.divider-theme($wzg-theme);
@include mat.form-field-theme($wzg-theme);
@include mat.sidenav-theme($wzg-theme);
@include mat.dialog-theme($wzg-theme);
@include mat.snack-bar-theme($wzg-theme);
@include mat.table-theme($wzg-theme);
@include mat.tabs-theme($wzg-theme);
@include mat.checkbox-theme($wzg-theme);
@include mat.toolbar-theme($wzg-theme);
@include mat.radio-theme($wzg-theme);
@include mat.card-theme($wzg-theme);
@include mat.paginator-theme($wzg-theme);
@include mat.sort-theme($wzg-theme);
@include mat.progress-bar-theme($wzg-theme);
@include mat.progress-spinner-theme($wzg-theme);
@include mat.input-theme($wzg-theme);
@include mat.list-theme($wzg-theme);
@include mat.menu-theme($wzg-theme);
@include mat.chips-theme($wzg-theme);
@include mat.autocomplete-theme($wzg-theme);
@include mat.select-theme($wzg-theme);

@include wzg.core-theme($wzg-theme);
@include wzg.home-theme($wzg-theme);
@include wzg.logo-theme($wzg-theme);
@include wzg.scrollbar-theme($wzg-theme);
@include wzg.search-field-theme($wzg-theme);
@include wzg.sign-in-theme($wzg-theme);
@include wzg.site-theme($wzg-theme);

.dark {
  @include mat.core-color($wzg-dark-theme);
  @include mat.option-color($wzg-dark-theme);
  @include mat.ripple-color($wzg-dark-theme);
  @include mat.pseudo-checkbox-color($wzg-dark-theme);
  @include mat.button-color($wzg-dark-theme);
  @include mat.icon-color($wzg-dark-theme);
  @include mat.divider-color($wzg-dark-theme);
  @include mat.form-field-color($wzg-dark-theme);
  @include mat.sidenav-color($wzg-dark-theme);
  @include mat.dialog-color($wzg-dark-theme);
  @include mat.snack-bar-color($wzg-dark-theme);
  @include mat.table-color($wzg-dark-theme);
  @include mat.tabs-color($wzg-dark-theme);
  @include mat.checkbox-color($wzg-dark-theme);
  @include mat.toolbar-color($wzg-dark-theme);
  @include mat.radio-color($wzg-dark-theme);
  @include mat.card-color($wzg-dark-theme);
  @include mat.paginator-color($wzg-dark-theme);
  @include mat.sort-color($wzg-dark-theme);
  @include mat.progress-bar-color($wzg-dark-theme);
  @include mat.progress-spinner-color($wzg-dark-theme);
  @include mat.input-color($wzg-dark-theme);
  @include mat.list-color($wzg-dark-theme);
  @include mat.menu-color($wzg-dark-theme);
  @include mat.chips-color($wzg-dark-theme);
  @include mat.autocomplete-color($wzg-dark-theme);
  @include mat.select-color($wzg-dark-theme);

  @include wzg.core-color($wzg-dark-theme);
  @include wzg.home-color($wzg-dark-theme);
  @include wzg.logo-color($wzg-dark-theme);
  @include wzg.scrollbar-color($wzg-dark-theme);
  @include wzg.search-field-color($wzg-dark-theme);
  @include wzg.sign-in-color($wzg-dark-theme);
  @include wzg.site-color($wzg-dark-theme);
}

wzg-home .mat-sidenav.dark {
  $dark-config: mat.get-color-config($wzg-dark-theme);
  $background: map.get($dark-config, background);
  background-color: mat.get-color-from-palette($background, card);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.search-field {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  input {
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
  }
}

.separator {
  flex: 1;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  .mat-icon {
    margin-left: 0;
    margin-right: 8px;
    margin-top: -2px;
    display: inline-block;

    &:first-child {
      margin-left: -4px;
    }

    &:last-child {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
}

.mat-tab-body .mat-toolbar:last-child {
  border-top-width: 1px;
  border-top-style: solid;
  justify-content: flex-end;
}

.cdk-drag-handle {
  opacity: 0.34;
  transition: opacity ease-in-out 0.2s;
  cursor: move;

  &:hover {
    opacity: 1;
  }
}

.cdk-drag-preview.mat-list-item .mat-divider {
  display: none;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0.34;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

::-webkit-scrollbar {
  $scrollbar-size: 6px;
  width: $scrollbar-size;
  height: $scrollbar-size;
}
::-webkit-scrollbar-button,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}
