@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $is-dark: map.get($config, is-dark);

  $scrollbar-background: mat.get-color-from-palette($background, app-bar);
  $scrollbar-color: mat.get-color-from-palette($foreground, secondary-text);

  ::selection {
    background-color: mat.get-color-from-palette($primary, darker);
    color: mat.get-color-from-palette($primary, darker-contrast);
  }

  a {
    color: mat.get-color-from-palette($primary, text);
  }

  .loading-shade {
    background-color: rgba(black, 0.54);
  }

  .mat-snack-bar-container.snackbar-error {
    &,
    .dark & {
      background: mat.get-color-from-palette($warn, darker);

      &,
      .mat-simple-snackbar-action {
        color: mat.get-color-from-palette($warn, darker-contrast);
      }
    }
  }

  .mat-list-base.list-with-columns {
    column-rule-color: mat.get-color-from-palette($foreground, divider);
  }

  .mat-expansion-panel.mat-elevation-z0 {
    border-color: mat.get-color-from-palette($foreground, divider);
    background: none;
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);

  .mat-button-base {
    text-transform: uppercase;
    letter-spacing: 0.09em;
  }
}
