@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $is-dark: map.get($config, is-dark);

  $scrollbar-background: mat.get-color-from-palette($background, app-bar);
  $scrollbar-color: mat.get-color-from-palette($foreground, secondary-text);

  * {
    scrollbar-color: $scrollbar-color $scrollbar-background;
  }

  ::-webkit-scrollbar {
    background-color: $scrollbar-background;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);
}
