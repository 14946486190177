@use "@angular/material" as mat;
@use "sass:map";

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $accent: map.get($config, "accent");
  $warn: map.get($config, "warn");
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $is-dark: map.get($config, "is-dark");

  .search-field {
    color: mat.get-color-from-palette($foreground, text);
    border-color: mat.get-color-from-palette($foreground, divider);

    input::placeholder {
      color: mat.get-color-from-palette($foreground, disabled);
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);

  .search-field {
    @include mat.typography-level($config, input);
    line-height: 3em;
  }
}
