@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  .mat-icon[svgicon="logo"] {
    .letter {
      fill: mat.get-color-from-palette($primary);

      .mat-toolbar.mat-primary & {
        fill: mat.get-color-from-palette($primary, default-contrast);

        > *:first-child {
          fill: mat.get-color-from-palette($primary, default-contrast, 0.5);
        }
      }

      > *:first-child {
        fill: mat.get-color-from-palette($primary, 0.5);
      }
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);
}
